<template>
  <div>{{ displayLoadTime }}</div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      displayLoadTime: '',
      intervalId: null,
      currentParams: this.params
    };
  },
  mounted() {
    this.calculateLoadTime();
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    calculateLoadTime() {
      const data = this.currentParams.node.data;
      const timeIn = data.timeIn;
      const timeOut = data.timeOut;

      // Clear any existing interval
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }

      if (timeIn) {
        if (timeOut) {
          // Both timeIn and timeOut are present
          const loadTime = this.calculateTimeDifference(timeIn, timeOut);
          this.displayLoadTime = loadTime;
        } else {
          // timeIn is present but timeOut is not
          this.updateLoadTime();
          // Start timer to update every second
          this.intervalId = setInterval(() => {
            this.updateLoadTime();
          }, 1000);
        }
      } else {
        this.displayLoadTime = '';
      }
    },
    updateLoadTime() {
      const data = this.currentParams.node.data; // Use currentParams
      const timeIn = data.timeIn;
      const now = new Date().toISOString(); // Current time in UTC
      const loadTime = this.calculateTimeDifference(timeIn, now);
      this.displayLoadTime = loadTime;
    },
    calculateTimeDifference(startTime, endTime) {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        const timeDifference = endDate.getTime() - startDate.getTime();

        const totalSeconds = Math.floor(timeDifference / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0'),
        ].join(':');
      } else {
        return 'Invalid Time';
      }
    },
    refresh(params) {
      this.currentParams = params; // Update the local copy
      this.calculateLoadTime();
      return true;
    },
  },
};
</script>
