import { onMounted, ref } from 'vue';

export function useMobileDetect() {
	const isMobile = ref(false);

	onMounted(() => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		isMobile.value = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
			userAgent.toLowerCase()
		);

	});

	return isMobile;
}
