import { createRouter, createWebHistory } from 'vue-router';
import LoadSheet from '../views/LoadSheet.vue';
import Login from '../views/LoginView.vue';
import store from '@/store';

const routes = [
	{
		path: '/',
		name: 'login',
		component: Login,
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('token')){
				next('/loadSheet');
			} else {
				next();
			}
		}
	},
	{
		path: '/loadSheet',
		name: 'loadSheet',
		component: LoadSheet,
		meta: { requiresAuth: true }
	},
	{
		path: '/logout',
		name: 'logout',
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('token')){
				localStorage.removeItem('token');
				store.commit('setSocket', null);
				store.commit('setConnectionStatus', false);
				next('/');
			} else {
				next('/');
			}
		}
	},
	{
		path: '/test',
		name: 'test',
		meta: { requiresAuth: true },
		beforeEnter: async (to, from, next) => {
			try {
				if(localStorage.getItem('token')){
					const token = JSON.parse(localStorage.getItem('token'));
					await store.dispatch('authenticate', {
						route: '/sso/auth',
						payload: {
							access_token: token
						}
					});
					next();
				} else {
					next('/');
				}
			} catch (error) {
				console.error('Authentication error in test route:', error);
				next('/');
			}
		}
	},
	{
		path: '/callback',
		name: 'callback',
		beforeEnter: (to, from, next) => {
			const authCode = to.query.code;
			let cleanup = null;

			const handleAuthResponse = (event) => {
				try {
					const data = JSON.parse(event.data);
					console.log("callback", data);
					if(data.token){
						localStorage.setItem('token', JSON.stringify(data.token.access_token));
						if (cleanup) cleanup();
						next('/loadSheet');
					}
				} catch (error) {
					console.error('Error handling auth response:', error);
					if (cleanup) cleanup();
					next('/');
				}
			};

			const handleError = (error) => {
				console.error('WebSocket error in callback route:', error);
				if (cleanup) cleanup();
				next('/');
			};

			cleanup = () => {
				if (store.state.socket) {
					store.state.socket.removeEventListener('message', handleAuthResponse);
					store.state.socket.removeEventListener('error', handleError);
				}
			};

			const initializeAndAuthenticate = async () => {
				try {
					store.dispatch('sendMessage', {
						route: '/sso/complete',
						auth_response: {
							code: authCode,
							clientKey: localStorage.getItem('clientKey')
						}
					});

					store.state.socket.addEventListener('message', handleAuthResponse);
					store.state.socket.addEventListener('error', handleError);

				} catch (error) {
					console.error('Error in authentication:', error);
					if (cleanup) cleanup();
					next('/');
				}
			};

			// Only initialize if needed
			if (!store.getters.isSocketConnected) {
				store.dispatch('initializeSocket', process.env.VUE_APP_SOCKET_PORT)
					.then(() => initializeAndAuthenticate())
					.catch((error) => {
						console.error('Socket initialization failed:', error);
						next('/');
					});
			} else {
				initializeAndAuthenticate();
			}
		}
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.onError((error) => {
	console.error('Router navigation error:', error);
	router.push('/');
});

// Simplified global navigation guard
router.beforeEach(async (to, from, next) => {
	// Skip auth check for login and callback routes
	if (['login', 'callback'].includes(to.name)) {
		return next();
	}

	// Check if route requires auth
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!localStorage.getItem('token')) {
			return next('/');
		}
	}

	next();
});

export default router;
