<template>
  <AgGridVue
      :allowDragFromColumnsToolPanel="false"
      :columnDefs="columnDefs"
      :components="frameworkComponents"
      :context="gridContext"
      :defaultColDef="defaultColDef"
      :floatingFilter="true"
      :getRowId="getRowId"
      :gridOptions="gridOptions"
      :theme="myTheme"
      animateRows="true"
      class="myGrid"
      @cellValueChanged="getCellChange"
      @gridReady="onGridReady"
  />
  <AddRowModal
      :visible="showAddRowModal"
      @close="closeAddRowModal"
      @save="handleSaveNewRow"
  />

</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { themeQuartz } from '@ag-grid-community/theming';

import timeSlotRenderer from "../grid/cellRenderers/timeSlotRenderer.vue";
import booleanCellRenderer from '../grid/cellRenderers/booleanCellRenderer.vue';
import dateCellRenderer from "../grid/cellRenderers/dateCellRenderer.vue";
import timeCellRendererCustom from '../grid/cellRenderers/timeCellRendererCustom.vue';
import loadTimeCellRenderer from '../grid/cellRenderers/loadTimeCellRenderer.vue';
import archiveCellRenderer from '../grid/cellRenderers/archiveCellRenderer.vue';
import utcDateRenderer from '../grid/cellRenderers/utcDateRenderer.vue';
import AddRowModal from '../modals/AddRowModal.vue';
import dateComparator from '../../utils/DateFilterComparator';
import StatusDropdownFilter from '../grid/StatusDropdownFilter.vue';
import StatusFloatingFilter from '../grid/StatusFloatingFilter.vue';
import { useMobileDetect } from '@/composables/useMobileDetect';

const timeRanges = [
  {
    text: '',
    value: null
  },
  {
    text: '8am - 9am',
    value: "08:00-09:00"
  },
  {
    text: '9am - 10am',
    value: "09:00-10:00"
  },
  {
    text: '10am - 11am',
    value: "10:00-11:00"
  },
  {
    text: '11am - 12pm',
    value: "11:00-12:00"
  },
  {
    text: '12pm - 1pm',
    value: "12:00-13:00"
  },
  {
    text: '1pm - 2pm',
    value: "13:00-14:00"
  },
  {
    text: '2pm - 3pm',
    value: "14:00-15:00"
  },
  {
    text: '3pm - 4pm',
    value: "15:00-16:00"
  }
]

export default {
  name: "ImAgGrid",
  components: {
    AgGridVue,
    AddRowModal
  },
  emits: [
    'saveCellChange',
    'saveNewRow',
    'gridReady',
    'updateDisplayedRowCount'
  ],
  data() {
    return {
      myTheme: themeQuartz
          .withParams({
            accentColor: "#087AD1",
            backgroundColor: "#FFFFFF",
            borderColor: "#D7E2E6",
            borderRadius: 2,
            browserColorScheme: "light",
            cellHorizontalPaddingScale: 0.16,
            chromeBackgroundColor: {
              ref: "backgroundColor"
            },
            columnBorder: false,
            fontFamily: {
              googleFont: "Inter"
            },
            fontSize: 13,
            foregroundColor: "#555B62",
            headerBackgroundColor: "#D9D9D9",
            headerFontSize: 13,
            headerFontWeight: 400,
            headerTextColor: "#141414",
            headerVerticalPaddingScale: 1,
            oddRowBackgroundColor: "#0000FF0F",
            rowBorder: true,
            rowVerticalPaddingScale: 0.8,
            sidePanelBorder: true,
            spacing: "2px",
            wrapperBorder: false,
            wrapperBorderRadius: 2
          }),
      suppressSave: false,
      showAddRowModal: false,
      gridApi: null,
      gridColumnApi: null,
      displayedRowCount: 0,
      columnDefs: [
        {
          headerName: "Row",
          valueGetter: "node.rowIndex + 1",
          width: 20,
          pinned: 'left'
        },
        {
          headerName: "Confirmed",
          field: "confirmed",
          cellRenderer: 'booleanCellRenderer',
          width: 20,
          pinned: 'left'
        },
        {
          headerName: "Load",
          field: "loadNo",
          filter: 'agTextColumnFilter',
          pinned: 'left'
        },
        {
          headerName: "Customer",
          field: "customer",
        },
        {
          headerName: "Final Stop",
          field: "name",
          valueGetter: params => `${params.data.finalStopName} (${params.data.finalStopCS})`,
          width: 460,
        },
        {
          headerName: "Due Date",
          field: "dueDate",
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: dateComparator
          },
          cellRenderer: 'utcDateRenderer'
        },
        {
          headerName: "Ready Date",
          field: "readyDate",
          filter: 'agDateColumnFilter',
          editable: params => params.data.confirmed,
          filterParams: {
            comparator: dateComparator
          },
          cellEditor: 'dateCellRenderer',
          cellRenderer: 'utcDateRenderer'
        },
        {
          headerName: "Orig Ship Date",
          field: "origShipDate",
          filter: 'agDateColumnFilter',
          editable: false,
          filterParams: {
            comparator: dateComparator
          },
          cellEditor: 'dateCellRenderer',
          cellRenderer: 'utcDateRenderer'
        },
        {
          headerName: "Ship Date",
          field: "shipDate",
          filter: 'agDateColumnFilter',
          editable: params => params.data.confirmed,
          filterParams: {
            comparator: dateComparator
          },
          cellEditor: 'dateCellRenderer',
          cellRenderer: 'utcDateRenderer'
        },
        {
          headerName: "Time Slot",
          field: "timeSlot",
          cellEditor: 'agSelectCellEditor',
          editable: params => params.data.confirmed,
          cellEditorParams: {
            values: timeRanges.map(item => item.value) // Map values only for the dropdown
          },
          valueFormatter: params => {
            // Find the corresponding label for the value
            const range = timeRanges.find(item => item.value === params.value);
            return range ? range.text : params.value;
          },
          valueParser: params => {
            // The selected value is directly saved
            return params.newValue;
          }
        },
        {
          headerName: "Equipment",
          field: "equipment",
          editable: params => params.data.confirmed
        },
        {
          headerName: "Truck Description",
          field: "truck",
          editable: params => params.data.confirmed
        },
        {
          headerName: "Staged",
          field: "staged",
          width: 100,
          suppressHeaderMenuButton: true,
          cellRenderer: 'booleanCellRenderer',
          cellRendererParams: {
            getConfirmed: (params) => params.data.confirmed
          }
        },
        {
          headerName: "QC",
          field: "qc",
          width: 100,
          suppressHeaderMenuButton: true,
          cellRenderer: 'booleanCellRenderer',
          cellRendererParams: {
            getConfirmed: (params) => params.data.confirmed
          }
        },
        {
          headerName: "Paperwork",
          field: "paperwork",
          width: 100,
          suppressHeaderMenuButton: true,
          cellRenderer: 'booleanCellRenderer',
          cellRendererParams: {
            getConfirmed: (params) => params.data.confirmed
          }
        },
        {
          headerName: "Time IN",
          field: "timeIn",
          cellRenderer: 'timeCellRendererCustom',
          cellRendererParams: {
            getConfirmed: (params) => params.data.confirmed
          }
        },
        {
          headerName: "Time OUT",
          field: "timeOut",
          cellRenderer: 'timeCellRendererCustom',
          cellRendererParams: {
            getConfirmed: (params) => params.data.confirmed
          }
        },
        {
          headerName: "Load Time",
          field: "loadTime",
          cellRenderer: 'loadTimeCellRenderer'
        },
        {
          headerName: 'Status',
          field: 'status',
          pinned: 'right',
          editable: params => params.data.confirmed,
          cellRenderer: 'archiveCellRenderer',
          filter: StatusDropdownFilter,
          floatingFilter: true,
          floatingFilterComponent: StatusFloatingFilter,
          width: 20
        }
      ],
      defaultColDef: {
        editable: false,
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
        minWidth: 100,
        enableCellChangeFlash: true,
      },
      frameworkComponents: {
        timeSlotRenderer,
        timeCellRendererCustom,
        booleanCellRenderer,
        dateCellRenderer,
        loadTimeCellRenderer,
        archiveCellRenderer,
        utcDateRenderer
      },
      gridOptions: {
        suppressColumnVirtualisation: false,
        onCellKeyDown: function(event) {
          if (event.event.key === ' ' || event.event.key === 'Enter') {
            const cellRendererInstances = event.api.getCellRendererInstances({
              rowNodes: [event.node],
              columns: [event.column],
            });

            cellRendererInstances.forEach(function(cellRendererInstance) {
              if (event.node.data.confirmed) {
                if (cellRendererInstance && cellRendererInstance.displayTime && cellRendererInstance.showClearButton && cellRendererInstance.clearTime) {
                  event.event.preventDefault();
                  cellRendererInstance.clearTime();
                } else if (cellRendererInstance && !cellRendererInstance.displayTime && cellRendererInstance.handleTimer) {
                  event.event.preventDefault();
                  cellRendererInstance.handleTimer();
                } else if (cellRendererInstance && cellRendererInstance.toggleCheckbox) {
                  event.event.preventDefault();
                  cellRendererInstance.toggleCheckbox();
                }
              }
            });
          }
        },
        getRowStyle: function (params) {
          if (params.data.status === 'removed') {
            return {
              background: '#ff6666',
              color: '#f6f6f6'
            };
          } else if (params.data.dueDate){
            const dueDate = new Date(params.data.dueDate);
            const currentDate = new Date();
            if (dueDate < currentDate) {
              return {
                color: '#bd2222'
              };
            }
          }
        }
      },
      gridContext: null,
      isMobile: null
    };
  },
  created() {
    this.gridContext = { componentParent: this };
    this.isMobile = useMobileDetect();
  },
  methods: {
    setSuppressSave(flag) {
      this.suppressSave = flag;
    },
    getRowId(params) {
      return params.data.loadNo.toString();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.$emit('gridReady', this.gridApi);

      //if mobile make sure all columns are not pinned
      if(this.isMobile){
        this.gridApi.getColumns().forEach(column => {
          column.setPinned(null);
        });
      }

      this.updateDisplayedRowCount();

      this.gridApi.addEventListener('rowDataChanged', this.updateDisplayedRowCount);
      this.gridApi.addEventListener('rowDataUpdated', this.updateDisplayedRowCount);
      this.gridApi.addEventListener('modelUpdated', this.updateDisplayedRowCount);

      this.sortGrid();
    },
    openAddRowModal() {
      this.showAddRowModal = true;
    },
    closeAddRowModal() {
      this.showAddRowModal = false;
    },
    handleSaveNewRow(newRowData) {
      newRowData.confirmed = ['1', 'true', 1, true].includes(newRowData.confirmed);
      this.$emit('saveNewRow', newRowData);
    },
    sortGrid() {
      console.log(this.gridApi);

      this.gridApi.getColumnFilterInstance('status').then(filterInstance => {
        if (!filterInstance) {
          console.error('Filter instance for "status" not found');
          return;
        }

        filterInstance.setModel({ value: 'Active' });

        this.gridApi.onFilterChanged();

        this.gridApi.applyColumnState({
          state: [
            {
              colId: 'shipDate',
              sort: 'desc',
              sortIndex: 0,
            },
            {
              colId: 'timeIn',
              sort: 'desc',
              sortIndex: 1,
            },
            {
              colId: 'loadNo',
              sort: 'desc',
              sortIndex: 2,
            },
          ],
          defaultState: { sort: null },
        });
      }).catch(err => {
        console.error('Error retrieving filter instance:', err);
      });
    },
    getCellChange(cell) {
      if (this.suppressSave) {
        return;
      }

      let columnName = '';
      let data = '';
      let cellHandler = cell;

      if (cell.currentValue !== undefined) {
        columnName = cell.currentColumn;
        data = Object.assign({}, cell.params.data);
        data[columnName] = cell.currentValue;
        cellHandler = cell.params;
      } else {
        columnName = cell.column.colId;
        data = Object.assign({}, cell.data);
      }

      if (columnName === 'shipDate' && !data.origShipDate) {
        data.origShipDate = data.shipDate;
        this.compileCellChange(cellHandler.data.loadNo, 'origShipDate', data.origShipDate);
      }

      this.compileCellChange(cellHandler.data.loadNo, columnName, data[columnName]);

      this.gridApi.refreshCells({ force: true, suppressFlash: true, rowNodes: [cellHandler.node] });
    },
    compileCellChange(loadNo, cell, value) {
      if (this.suppressSave) return;
      const data = {
        loadNo: loadNo,
        cellName: cell,
        value: value
      };
      this.$emit('saveCellChange', data);
    },
    getLoadTime(params) {
      let loadTime = null;

      let timeIn = params.data.timeIn;
      let timeOut = params.data.timeOut;

      if (timeIn && timeOut) {
        let timeInDate = new Date(timeIn);
        let timeOutDate = new Date(timeOut);

        if (!isNaN(timeInDate) && !isNaN(timeOutDate)) {
          let timeDifference = timeOutDate.getTime() - timeInDate.getTime();

          let totalSeconds = Math.floor(timeDifference / 1000);
          let hours = Math.floor(totalSeconds / 3600);
          let minutes = Math.floor((totalSeconds % 3600) / 60);
          let seconds = totalSeconds % 60;

          loadTime = [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
          ].join(':');
        }

        params.data.loadTime = loadTime;
        this.compileCellChange(params.data.loadNo, 'loadTime', loadTime);
      }
    },
    scrollToLoadNo(loadNo) {
      if (!this.gridApi) {
        console.warn("Grid API is not ready yet.");
        return;
      }

      const rowNode = this.gridApi.getRowNode(loadNo.toString());

      if (rowNode) {
        this.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');

        rowNode.setSelected(true);

        console.log(`Scrolled to loadNo: ${loadNo}`);
      } else {
        console.warn(`Row with loadNo ${loadNo} not found.`);
      }
    },
    updateDisplayedRowCount() {
      if (this.gridApi) {
        let count = 0;
        this.gridApi.forEachNode(node => {
          if (node.data.status === '') {
            count += 1;
          }
        });
        this.displayedRowCount = count;
        this.$emit('updateDisplayedRowCount', count);
        console.log("Displayed row count:", count);
      }
    },
  },
  expose: ['getLoadTime', 'closeAddRowModal', 'setSuppressSave', 'openAddRowModal'],
};
</script>

<style scoped>
.myGrid {
  width: 100%;
}

</style>
