<template>
  <div class="login-container">
    <h1>Login</h1>
    <button
        class="login-button"
        @click="signInWithMicrosoft"
        :disabled="isLoading"
    >
      {{ isLoading ? 'Connecting...' : 'Sign in with Microsoft' }}
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'LoginView',
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState(['socket']),
    ...mapGetters(['isSocketConnected'])
  },
  methods: {
    ...mapActions(['initializeSocket', 'sendMessage']),

    async signInWithMicrosoft() {
      try {
        this.isLoading = true;

        // Ensure we have a socket connection
        if (!this.isSocketConnected) {
          await this.initializeSocket(process.env.VUE_APP_SOCKET_PORT);
        }

        // Setup one-time message handler for the SSO response
        const handleSSOResponse = (event) => {
          try {
            const data = JSON.parse(event.data);
            console.log("SSO Response:", data);

            if (data.auth_uri) {
              // Store client key and redirect to Microsoft login
              localStorage.setItem('clientKey', data.clientKey);
              window.location.href = data.auth_uri;
            } else if (data.token) {
              console.log('Authentication successful:', data.token);
              localStorage.setItem('token', JSON.stringify(data.token));
              this.$router.push('/loadSheet');
            } else if (data.error) {
              console.error('SSO Error:', data.error);
              throw new Error(data.error);
            }
          } catch (error) {
            console.error('Error handling SSO response:', error);
          } finally {
            this.socket?.removeEventListener('message', handleSSOResponse);
            this.isLoading = false;
          }
        };

        // Add message handler
        this.socket.addEventListener('message', handleSSOResponse);

        // Start SSO flow
        await this.sendMessage({ route: '/sso/start' });

        // Set timeout for SSO response
        setTimeout(() => {
          this.socket?.removeEventListener('message', handleSSOResponse);
          this.isLoading = false;
        }, 30000);

      } catch (error) {
        console.error('Error during sign in:', error);
        this.isLoading = false;
      }
    },

    async handleAuthCallback(code, state) {
      try {
        this.isLoading = true;

        // Ensure socket connection
        if (!this.isSocketConnected) {
          await this.initializeSocket(process.env.VUE_APP_SOCKET_PORT);
        }

        // Setup one-time message handler for the completion response
        const handleCompletionResponse = (event) => {
          try {
            const data = JSON.parse(event.data);
            console.log("Completion Response:", data);

            if (data.token) {
              localStorage.setItem('token', JSON.stringify(data.token));
              this.$router.push('/loadSheet');
            } else if (data.error) {
              console.error('Completion Error:', data.error);
              this.$router.push('/');
            }
          } catch (error) {
            console.error('Error handling completion response:', error);
            this.$router.push('/');
          } finally {
            this.socket?.removeEventListener('message', handleCompletionResponse);
            this.isLoading = false;
          }
        };

        // Add message handler
        this.socket.addEventListener('message', handleCompletionResponse);

        // Send completion message
        await this.sendMessage({
          route: '/sso/complete',
          auth_response: {
            code,
            state,
            clientKey: localStorage.getItem('clientKey')
          }
        });

        // Set timeout for completion response
        setTimeout(() => {
          this.socket?.removeEventListener('message', handleCompletionResponse);
          this.isLoading = false;
          this.$router.push('/');
        }, 30000);

      } catch (error) {
        console.error('Error during auth callback:', error);
        this.isLoading = false;
        this.$router.push('/');
      }
    }
  },
  async mounted() {
    // Handle the redirect after Microsoft login
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code && state) {
      await this.handleAuthCallback(code, state);
    }
  },
  beforeUnmount() {
    // Cleanup any remaining event listeners
    if (this.socket) {
      this.socket.removeEventListener('message', this.handleSSOResponse);
      this.socket.removeEventListener('message', this.handleCompletionResponse);
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding-top: 50px;
  background-color: #000000;
  overflow: hidden;
}

h1 {
  margin-bottom: 20px;
  color: white;
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover:not(:disabled) {
  background-color: #005a9e;
}

.login-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}
</style>
