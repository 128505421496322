<template>
  <div>
    <template v-if="showButton">
      <button :disabled="!isEditable" class="status-button" @click="toggleArchiveStatus">
        {{ currentValue ? 'Unarchive' : 'Archive' }}
      </button>
    </template>
  </div>
</template>
<script>
export default {
  props: ['params'],
  data() {
    return {
      currentValue: false,
      isEditable: false,
      showButton: false,
    };
  },
  beforeMount() {
    if (this.params.data.dataSource === 'API') {
      this.showButton = true;
      this.isEditable = this.params.data.confirmed;
      this.currentValue = this.params.data.status === 'removed';
    } else {
      this.showButton = false;
    }
  },
  methods: {
    toggleArchiveStatus() {
      this.currentValue = !this.currentValue;
      if (this.currentValue) {
        this.params.node.setDataValue('status', 'removed');
      } else {
        this.params.node.setDataValue('status', '');
      }
      this.params.api.redrawRows({ rowNodes: [this.params.node] });
      this.params.api.onFilterChanged();
    }
  }
};
</script>


<style>

.status-button {
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: #087AD1;
  padding:1px 4px 1px 4px;
  color:white;
}
.status-button:hover {
  background-color: #066bb3;
}



</style>
