<script>
export default {
  data() {
    return {
      selectedValue: ''
    };
  },
  methods: {
    isFilterActive() {
      return this.selectedValue !== '';
    },
    doesFilterPass(params) {
      const cellValue = params.data.status;
      if (this.selectedValue === 'Archived') {
        return cellValue === 'removed';
      } else if (this.selectedValue === 'Active') {
        return cellValue === '';
      }
      return true;
    },
    getModel() {
      return { value: this.selectedValue };
    },
    setModel(model) {
      this.selectedValue = model ? model.value : '';
    }
  }
};
</script>
