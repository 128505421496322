<template>
  <div>
    <input
        type="date"
        v-model="currentValue"
        @keydown.enter="onEnterPressed"
        @blur="onDateBlur"
        ref="dateInput"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentValue: null,
      timeIn: '',
    };
  },
  beforeMount() {
    this.currentValue = this.params.value;
    this.timeIn = this.params.data.timeIn;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.dateInput) {
        this.$refs.dateInput.focus();
      }
    });
  },
  methods: {
    onEnterPressed() {
      if (this.isValidDate(this.currentValue)) {
        this.params.api.stopEditing();
        this.refocusGrid();
      }
    },
    onDateBlur() {
      if (this.isValidDate(this.currentValue)) {
        this.params.api.stopEditing();
        this.refocusGrid();
      }
    },
    refocusGrid() {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.column.colId);
    },
    isValidDate(dateString) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(dateString);
    },
    getValue() {
      return this.currentValue;
    },
  },
};
</script>
