export default function dateComparator(dateFromFilter, cellValue) {
	if (!cellValue) {
		return 0;
	}

	// Parse the cellValue in 'YYYY-MM-DD' format
	const [year, month, day] = cellValue.split('-').map(Number);

	// Create the date in UTC without time offset issues
	const cellDate = new Date(Date.UTC(year, month - 1, day));

	// Zero out time on cellDate and dataFromFilter for a pure date comparison
	cellDate.setUTCHours(0, 0, 0, 0);
	dateFromFilter.setUTCHours(0, 0, 0, 0);

	if (cellDate < dateFromFilter) {
		return -1;
	} else if (cellDate > dateFromFilter) {
		return 1;
	}
	return 0;
}
