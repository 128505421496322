<template>
  <div>
    {{ formattedDate }}
  </div>
</template>

<script>
export default {
  props: ['params'],
  computed: {
    formattedDate() {
      if (!this.params.value) return '';

      // Create the date in UTC by appending 'T00:00:00Z'
      const date = new Date(this.params.value + 'T00:00:00Z');

      if (isNaN(date.getTime())) return '';

      // Format date as MM-DD-YYYY using UTC methods
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();

      return `${month}-${day}-${year}`;
    }
  }
};
</script>
