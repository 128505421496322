<template>
  <div class="status-filter-container">
    <select
        @change="onDropdownChange"
        v-model="selectedValue"
        class="status-select"
    >
      <option value="">All</option>
      <option value="Active">Active</option>
      <option value="Archived">Archived</option>
    </select>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      selectedValue: ''
    };
  },
  methods: {
    onDropdownChange() {
      this.params.parentFilterInstance(instance => {
        instance.setModel({ value: this.selectedValue });
        this.params.api.onFilterChanged();
      });
    },
    onParentModelChanged(model) {
      this.selectedValue = model ? model.value : '';
    }
  }
};
</script>

<style>
.status-filter-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-width: 0;
  width: 100%;
}

.status-select {
  flex-grow: 1;
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
