<template>
  <div v-if="visible" class="modal-overlay" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-content" ref="modalContent" tabindex="0" role="document">
      <button class="close-button" @click="closeModal" aria-label="Close Modal">
        &times;
      </button>

      <h3>Add New Load</h3>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="loadNo">Load Number *</label>
          <input type="text" id="loadNo" v-model="formData.loadNo" required />
        </div>

        <div class="form-group">
          <label for="dueDate">Due Date *</label>
          <input type="date" id="dueDate" v-model="formData.dueDate" required />
        </div>

        <div class="form-group">
          <label for="customer">Customer *</label>
          <input type="text" id="customer" v-model="formData.customer" required />
        </div>

        <div class="form-group">
          <label for="finalStopName">Final Stop Name *</label>
          <input type="text" id="finalStopName" v-model="formData.finalStopName" required />
        </div>

        <div class="form-group">
          <label for="finalStopCity">Final Stop City *</label>
          <input type="text" id="finalStopCity" v-model="formData.finalStopCity" required />
        </div>

        <div class="form-group">
          <label for="finalStopState">Final Stop State *</label>
          <input type="text" id="finalStopState" v-model="formData.finalStopState" required />
          <!-- Alternatively, use a select dropdown for states:
          <select id="finalStopState" v-model="formData.finalStopState" required>
            <option disabled value="">Please select a state</option>
            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
          </select>
          -->
        </div>

<!--        <div class="form-group">-->
<!--          <label>Final Stop (City, State)</label>-->
<!--          <input type="text" :value="computedFinalStopCS" readonly />-->
<!--        </div>-->

        <div class="form-actions">
          <button type="submit" :disabled="isSubmitting">Save</button>
          <button type="button" @click="closeModal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddRowModal",
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'save'],
  data() {
    return {
      states: ['SD', 'OR', 'CA', 'NY', 'TX', 'FL'],
      formData: {
        loadNo: '',
        customer: '',
        dueDate: '',
        finalStopName: '',
        finalStopCS: '',
        finalStopCity: '',
        finalStopState: '',
        equipment: '',
        truck: '',
        staged: false,
        paperwork: false,
        qc: false
      },
      isSubmitting: false
    };
  },
  computed: {
    computedFinalStopCS() {
      if (this.formData.finalStopCity && this.formData.finalStopState) {
        return `${this.formData.finalStopCity}, ${this.formData.finalStopState}`;
      }
      return '';
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    submitForm() {
      if (this.isSubmitting) return;

      const payload = { ...this.formData };
      payload.finalStopCS = `${payload.finalStopCity}, ${payload.finalStopState}`;

      try {
        this.isSubmitting = true;
        this.$emit('save', payload);
      } catch (error) {
        console.error('Save failed:', error);
        this.$toast.error('Failed to save the load. Please try again.');
      } finally {
        this.isSubmitting = false;
      }
    },
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    },
    resetForm() {
      this.formData = {
        loadNo: '',
        customer: '',
        dueDate: '',
        finalStopName: '',
        finalStopCS: '',
        finalStopCity: '',
        finalStopState: '',
        equipment: '',
        truck: '',
        staged: false,
        paperwork: false,
        qc: false
      };
    }
  },
  mounted() {
    document.body.classList.add('modal-open');

    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);

    document.body.classList.remove('modal-open');
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.modalContent) {
            this.$refs.modalContent.focus();
          }
        });
      } else {
        this.resetForm();
      }
    }
  }
};
</script>

<style scoped>
/* Basic modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  position: relative;
  background-color: #181717;
  padding: 2rem;
  border-radius: 5px;
  width: 400px;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}
.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}
.form-group input,
.form-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
}
.form-actions button {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.form-actions button[type="submit"] {
  background-color: #28a745;
  color: #fff;
}
.form-actions button[type="button"] {
  background-color: #dc3545;
  color: #fff;
}
.form-actions button[type="submit"]:hover {
  background-color: #218838;
}

.form-actions button[type="button"]:hover {
  background-color: #a12631;
}
body.modal-open {
  overflow: hidden;
}
</style>
