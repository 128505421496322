<template>
  <div class="app-container">
    <header class="app-header">
      <div class="header-section left-section">
        <button class="button" @click="openAddRowModal">Add New Load</button>
        <span class="load-count">Loads - {{ displayedRowCount }}</span>
      </div>

      <div class="header-section right-section">
        <MainHeader />
        <div class="status-container">
          <span class="status-label">Status</span>
          <div :class="statusClass" id="status"></div>
        </div>
        <router-link to="/logout" class="logout-link">Logout</router-link>
      </div>
    </header>

    <ImAgGrid
        ref="myGridRef"
        @getStatus="getStatus"
        @gridReady="onGridReady"
        @saveCellChange="saveCellChange"
        @saveNewRow="saveNewRow"
        @updateDisplayedRowCount="updateDisplayedRowCount"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ImAgGrid from '@/components/grid/ImAgGrid.vue';
import MainHeader from "@/components/MainHeader.vue";

function verifyCookies() {
  // Initialize localStorage items if not present
  if (localStorage['sessionID'] === undefined) {
    let sessionID = Math.floor(Math.random() * 1000000000);
    localStorage['sessionID'] = sessionID;
  }

  if (localStorage['sync'] === undefined) {
    let sync = 0;
    localStorage['sync'] = sync;
  }

  if (localStorage['socketStatus'] === undefined) {
    localStorage['socketStatus'] = '';
  }
}

verifyCookies();

const settings = {
  socket: {
    port: process.env.VUE_APP_SOCKET_PORT
  },
  name: process.env.VUE_APP_NAME
};

export default {
  name: "LoadSheet",
  components: {
    MainHeader,
    ImAgGrid
  },
  data() {
    return {
      settings: settings,
      tempData: [],
      gridData: [],
      sessionID: null,
      clients: null,
      gridApi: null,
      newLoadNo: null,
      displayedRowCount: 0
    }
  },
  computed: {
    ...mapState(['socket']),
    ...mapGetters(['connectionStatus', 'isSocketConnected']),
    statusClass() {
      return this.connectionStatus ? 'greenBall' : 'redBall';
    }
  },
  mounted() {
    console.log("loadsheet mounted", this);

    // Setup socket message handler
    this.setupSocketMessageHandler();

    if (settings.name) {
      document.title = settings.name;
    } else {
      document.title = 'Load Sheet';
    }

    // Initial load sheet fetch if socket is connected
    if (this.isSocketConnected) {
      this.getLoadSheet();
    }
  },
  methods: {
    ...mapActions(['sendMessage', 'initializeSocket']),

    setupSocketMessageHandler() {
      if (!this.socket) return;

      this.socket.onmessage = (event) => {
        const res = JSON.parse(event.data);
        console.log('event', res);
        let loadSheet = null;

        if (res.type === 'API') {
          this.getLoadSheet();
        } else if (res.data && !res.data.success) {
          localStorage.removeItem('token');
          this.$router.push('/');
        } else {
          switch (res.data.route) {
            case '/getLoadSheet':
              loadSheet = res.data.data.loadSheet;
              localStorage.setItem('token', JSON.stringify(res.data.token));
              this.updateGridData(loadSheet);

              console.log("Load Sheet loaded.");

              if (this.newLoadNo) {
                this.$nextTick(() => {
                  this.scrollToLoadNo(this.newLoadNo);
                  this.newLoadNo = null;
                });
              }
              break;

            case '/saveCellChange':
              this.processUpdates(res.data.data);
              localStorage['socketStatus'] = '';
              break;

            case '/saveNewRow':
              console.log("save new data success", res.data);
              localStorage['socketStatus'] = '';

              if (res.data.data && res.data.data.loadNo) {
                this.newLoadNo = res.data.data.loadNo;
              }

              if (this.$refs.myGridRef && typeof this.$refs.myGridRef.closeAddRowModal === 'function') {
                this.$refs.myGridRef.closeAddRowModal();

                if (res.data.data.duplicateRow) {
                  this.$toast.error('Load already exists!');
                } else {
                  this.$toast.success('Load added successfully!');
                }
              }
              break;

            case '/getLoadSheetUpdate':
              this.getLoadSheet();
              break;

            default:
              break;
          }
        }
      };
    },

    updateGridData(loadSheet) {
      if (!this.gridApi) {
        console.warn("Grid API is not ready yet.");
        return;
      }

      this.$refs.myGridRef.setSuppressSave(true);

      const existingData = {};
      const newData = {};

      this.gridApi.forEachNode(node => {
        existingData[node.data.loadNo] = node;
      });

      loadSheet.forEach(row => {
        newData[row.loadNo] = row;
      });

      const transactions = {
        update: [],
        add: [],
        remove: []
      };

      loadSheet.forEach(row => {
        const loadNo = row.loadNo;
        const existingNode = existingData[loadNo];

        if (existingNode) {
          let hasChanges = false;
          Object.keys(row).forEach(column => {
            if (existingNode.data[column] !== row[column]) {
              existingNode.setDataValue(column, row[column]);
              hasChanges = true;
            }
          });

          if (hasChanges) {
            transactions.update.push(row);
          }
        } else {
          transactions.add.push(row);
        }
      });

      Object.keys(existingData).forEach(loadNo => {
        if (!newData[loadNo]) {
          transactions.remove.push(existingData[loadNo].data);
        }
      });

      if (transactions.add.length > 0 || transactions.update.length > 0 || transactions.remove.length > 0) {
        this.gridApi.applyTransaction({
          add: transactions.add,
          update: transactions.update,
          remove: transactions.remove
        });

        console.log("Grid data updated with new transactions:", transactions);
      } else {
        console.log("No changes detected in load sheet data.");
      }

      setTimeout(() => {
        this.$refs.myGridRef.setSuppressSave(false);
      }, 0);
    },

    onGridReady(gridApi) {
      this.gridApi = gridApi;
      console.log("Grid API is ready:", this.gridApi);
    },

    getStatus(value) {
      console.log("Connection status:", value);
    },

    getClientCount(value) {
      console.log("CLIENTS:", value);
      this.clients = value;
    },

    saveCellChange(event) {
      console.log("Saving cell change:", event);
      if (this.isSocketConnected) {
        const token = JSON.parse(localStorage.getItem('token'));
        this.sendMessage({
          type: 'PUT',
          route: '/saveCellChange',
          data: event,
          access_token: token
        });
        localStorage['socketStatus'] = 'sending';
      } else {
        alert("Socket is not connected. Please try again later.");
      }
    },

    processUpdates(updates) {
      if (updates) {
        const loadNo = updates.loadNo;
        const cellName = updates.cellName;
        const value = updates.value;

        console.log("Processing update:", loadNo, cellName, value);

        const rowNode = this.gridApi.getRowNode(loadNo.toString());

        if (rowNode) {
          this.$refs.myGridRef.setSuppressSave(true);

          rowNode.setDataValue(cellName, value);

          if (cellName === 'confirmed') {
            this.gridApi.refreshCells({
              rowNodes: [rowNode],
              flash: true,
              force: true
            });
          } else {
            this.gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [cellName],
              flash: true,
              force: true
            });
          }

          const { timeIn, timeOut, loadTime } = rowNode.data;

          if (timeIn || timeOut) {
            this.gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: ['loadTime'],
              flash: true,
              force: true
            });
          }

          setTimeout(() => {
            this.$refs.myGridRef.setSuppressSave(false);

            if (timeIn && timeOut && (cellName === 'loadTime' && updates.value !== loadTime)) {
              console.log("Calculating load time...");
              this.$refs.myGridRef.getLoadTime({
                data: rowNode.data,
                node: rowNode,
                api: this.gridApi,
              });
            }
          }, 0);

        } else {
          console.log("No matching loadNo found in the grid for:", loadNo);
        }
      }
    },

    getLoadSheet() {
      console.log("Fetching load sheet...");
      if (this.isSocketConnected) {
        let token;

        try {
          token = JSON.parse(localStorage.getItem('token'));
        } catch (e) {
          console.log("Invalid token, redirecting to login.");
          this.$router.push('/');
          return;
        }

        if (!token) {
          this.$router.push('/');
        } else {
          this.sendMessage({
            type: 'GET',
            route: '/getLoadSheet',
            onlyMe: true,
            access_token: token
          });
          console.log("Sent load sheet request.");
        }
      }
    },

    saveNewRow(event) {
      console.log("Saving new row:", event);
      if (this.isSocketConnected) {
        let token;
        try {
          token = JSON.parse(localStorage.getItem('token'));
        } catch (e) {
          console.log("Invalid token, redirecting to login.");
          this.$router.push('/');
          return;
        }
        if (!token) {
          this.$router.push('/');
        } else {
          this.sendMessage({
            type: 'POST',
            route: '/saveNewRow',
            data: event,
            access_token: token
          });
          localStorage['socketStatus'] = 'sending';
        }
      } else {
        alert("Socket is not connected. Please try again later.");
      }
    },

    scrollToLoadNo(loadNo) {
      if (!this.gridApi) {
        console.warn("Grid API is not ready yet.");
        return;
      }

      const rowNode = this.gridApi.getRowNode(loadNo.toString());

      if (rowNode) {
        this.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        rowNode.setSelected(true);
        console.log(`Scrolled to loadNo: ${loadNo}`);
      } else {
        console.warn(`Row with loadNo ${loadNo} not found.`);
      }
    },

    openAddRowModal() {
      if (this.$refs.myGridRef && typeof this.$refs.myGridRef.openAddRowModal === 'function') {
        this.$refs.myGridRef.openAddRowModal();
      } else {
        console.error("ImAgGrid component is not ready or does not have openAddRowModal method.");
      }
    },

    updateDisplayedRowCount(count) {
      this.displayedRowCount = count;
    },
  },
  watch: {
    socket: {
      handler(newSocket) {
        if (newSocket) {
          this.setupSocketMessageHandler();
          if (newSocket.readyState === 1) {
            this.getLoadSheet();
          }
        }
      },
      immediate: true
    },
    connectionStatus(nVal) {
      console.log("Connection status changed to:", nVal);
    },
    gridData(nVal) {
      if (nVal && Array.isArray(nVal)) {
        let count = 0;
        nVal.forEach(row => {
          if (row.status === '') count += 1;
        });
        this.displayedRowCount = count;
      }
    }
  },
};
</script>

<style scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.25rem; /* 10px 20px */
  background-color: #e7e7e7;
  border-bottom: 1px solid #ccc;
  flex-wrap: wrap;
}

.header-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.left-section .status-label {
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}

#status {
  width: 0.9375rem; /* 15px */
  height: 0.9375rem;
  border-radius: 50%;
  margin-right: 1.25rem;
}

.button {
  padding: 0.5rem 1rem;
  background-color: #087AD1;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  margin-right: 1.25rem;
}

.button:hover {
  background-color: #066bb3;
}

.load-count {
  font-size: 1rem;
  color: #333333;
}

.right-section .logout-link {
  margin-right: 1.25rem;
  font-size: 1rem;
  color: #087AD1;
  text-decoration: none;
}

.right-section .logout-link:hover {
  text-decoration: underline;
}

/* Mobile styles */
@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }

  .header-section {
    margin-bottom: 0.625rem;
  }

  .left-section, .right-section {
    width: 100%;
    justify-content: flex-start;
  }

  .button, .load-count, .logout-link, .status-label, #status {
    margin-right: 0.625rem;
    margin-bottom: 0.3125rem;
  }

  .button {
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
  }

  .load-count, .right-section .logout-link, .status-label {
    font-size: 0.875rem;
  }

  #status {
    width: 0.75rem;
    height: 0.75rem;
  }
}

/* Extra small devices */
@media (max-width: 480px) {
  .button, .load-count, .logout-link, .status-label {
    margin-right: 0.3125rem;
    font-size: 0.8125rem;
  }

  .button {
    padding: 0.3125rem 0.625rem;
  }

  .app-header {
    padding: 0.3125rem 0.625rem;
  }

  #status {
    width: 0.625rem;
    height: 0.625rem;
  }
}
</style>
